/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components

import * as constants from "../../../constants"

import { withRouter } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "../../../components/Headers/AuthHeader.jsx";
// let history = useHistory()
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      username: '',
      error: '',
      remember:'',

    }
  }
  componentDidMount() {

    let jwt = localStorage.getItem('token')
    // let remember = null
    let remember = localStorage.getItem('remember')
    if (jwt && remember) {
      fetch(constants.url+"/portal/validateToken" , {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'JWT ' + jwt,
        },
        })
        .then(res => res.json())
        .then(
          (result) => {
            this.props.history.push("/admin/shipment")
          },
        )
    }
  }
  gotoDash() {
    fetch(constants.url+"/portal/login", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        "email": this.state.username,
        "password": this.state.password
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          if(result.error){
            this.setState({
              isLoaded: true,
              error: 'wachtwoord of gebruiker onjuist'
  
            });
          } else{
          localStorage.setItem('token', result.token)
          localStorage.setItem('isadmin', result.isAdmin)
          localStorage.setItem('isMagazijn', result.isMagazijn)
          localStorage.setItem('isDriver', result.isDriver)
          localStorage.setItem('name', result.name)
          localStorage.setItem('remember', this.state.remember)
          this.props.history.push("/admin/shipment")
        }
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error: 'wachtwoord of gebruiker onjuist'

          });
        }
      )
  };

  render() {
    return (
      <>
        <AuthHeader
          title=""
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent py-4">
                  <div className="text-muted text-center mt-2 mb-3">
                  </div>
                  <div className="btn-wrapper text-center">
                    <img
                      className="login-image py-2"
                      alt="..."
                      src={"http://joosexpress.be/images/JoosExpress.jpg"}
                    />
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                  </div>
                  <Form role="form">
                    <FormGroup
                      className={classnames("mb-3", {
                        focused: this.state.focusedEmail
                      })}
                    >
                      <p>{this.state.error}</p>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Email"
                          type="email"
                          onChange={e => { this.state.username = e.target.value }}
                          onFocus={() => this.setState({ focusedEmail: true })}
                          onBlur={() => this.setState({ focusedEmail: false })}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: this.state.focusedPassword
                      })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type="password"
                          onChange={e => { this.state.password = e.target.value }}
                          onFocus={() =>
                            this.setState({ focusedPassword: true })
                          }
                          onBlur={() =>
                            this.setState({ focusedPassword: false })
                          }
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                        onChange= {()=> this.setState({remember: !this.state.remember})}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Remember me</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="button" onClick={() => this.gotoDash()}>
                        Sign in
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    <small>Forgot password?</small>
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a
                    className="text-light"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Login);
