import React from "react";
// javascript plugin that creates a sortable object from a dom object
import List from "list.js";
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Button,
  Modal,
  Input,
  UncontrolledTooltip, ToastBody,
  FormGroup,
  CardBody
} from "reactstrap";
// core components
import swal from 'sweetalert';
import * as constants from "../../../constants"

class shipmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      exampleModal: false,
      fileName: "Kies Bestand",
      addModal: false,
      shipments: [],
      selected: 'ikeaDate'
    }

  }
  // state = {

  // };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  uploadShipment() {
    var formData = new FormData();
    formData.append('csv', this.state.selectedFile)
    fetch(constants.url+"/orders/upload", {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.toggleModal("exampleModal")
          swal("success", "orders uploaded", "success");
        },

        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  createTable = () => {
    let table = []

    for (let i = 0; i < this.state.shipments.length; i++) {
      let shippedIkea = (new Date(this.state.shipments[i].shippedIkea * 1000))
      let receivedJoos = (new Date(this.state.shipments[i].receivedJoos * 1000))
      let acceptedJoos = (new Date(this.state.shipments[i].acceptedJoos * 1000))
      let departedJoos = (new Date(this.state.shipments[i].departedJoos * 1000))
      table.push(
        <tr>
          <td className="budget">{this.state.shipments[i].orderID}</td>
          <td className="budget">{this.state.shipments[i].name}</td>
          <td className="budget">{this.state.shipments[i].postal}</td>
          <td className="budget">{this.state.shipments[i].city}</td>
          <td className="budget">{this.state.shipments[i].adress}</td>
          <td className="budget">{this.state.shipments[i].phone}</td>
          <td className="budget">{(this.state.shipments[i].shippedIkea) ? shippedIkea.getDate() + '/' + shippedIkea.getMonth() + '/' + shippedIkea.getFullYear() : ''}</td>
          <td className="budget">{(this.state.shipments[i].receivedJoos) ? receivedJoos.getDate() + '/' + receivedJoos.getMonth() + '/' + receivedJoos.getFullYear() : ''}</td>
          <td className="budget">{(this.state.shipments[i].acceptedJoos) ? acceptedJoos.getDate() + '/' + acceptedJoos.getMonth() + '/' + acceptedJoos.getFullYear() : ''}</td>
          <td className="budget">{(this.state.shipments[i].departedJoos) ? departedJoos.getDate() + '/' + departedJoos.getMonth() + '/' + departedJoos.getFullYear() : ''}</td>
          <td className="budget">{this.state.shipments[i].volume}</td>
          <td className="budget">{this.state.shipments[i].weight}</td>
          <td className="budget">{this.state.shipments[i].method}</td>
          <td className="budget">{this.state.shipments[i].firstName}</td>
        </tr>)
    }
    // this.setState({userRows:table})
    // this.state.userRows = table
    return table
  }
  search() {
    if(this.state.startDate && this.state.endDate && this.state.selected){
      this.fetchShipments(Date.parse(this.state.startDate), Date.parse(this.state.endDate), this.state.selected)
      this.setState({error:''});
      } else{
        this.setState({error:' vul alle velden in aub'});
      }
  }
  fetchShipments(start, end, type) {
    fetch(constants.url+"/orders/find/" + start + "/" + end + "/" + type, {
      headers: {
        'Authorization': 'JWT ' + localStorage.getItem('token'),
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ shipments: result })
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  componentDidMount() {
    let startDate = new Date()
    startDate.setDate(startDate.getDate() - 1)
    startDate = startDate.getTime()
    this.fetchShipments(startDate, (Date.now()), 'ikeaDate')
    fetch(constants.url+"/portal/validateToken" , {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + localStorage.getItem('token'),
      },
      })
      .then(res => res.json())
      .then(
        (result) => {
        },
        (error) => {
        this.props.history.push("/")
        }
      )
  }
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exampleModal}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Shipments toevoegen
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div>
                <div style={{ display: 'flex' }}>

                  <div style={{ marginLeft: '20px' }}>
                    <div className="custom-file">
                      <input className="custom-file-input" id="customFileLang" lang="en" type="file" onChange={(event) => this.setState({ selectedFile: event.target.files[0], fileName: event.target.files[0].name })} />
                      <label className="custom-file-label" htmlFor="customFileLang">{this.state.fileName}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <div className="modal-footer">
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.toggleModal("exampleModal")}>
              Close
            </Button>
            <Button color="primary" type="button" onClick={() => this.uploadShipment()}>
              upload csv
            </Button>
          </div>
        </Modal>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    Overzicht
                  </h6>{" "}

                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <Button className="btn-neutral" color="default" size="sm" onClick={() => this.toggleModal("exampleModal")}>
                    add shipment
                  </Button>
                </Col>
              </Row>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Card className="card-frame p-3" style={{ display: 'flex', flexDirection: 'row', width: '31%' }}>
                <div >
                  <div className="custom-control custom-radio">
                    <input
                    defaultChecked
                      className="custom-control-input"
                      id="customRadio1"
                      name="customRadio"
                      type="radio"
                      onChange={() => this.setState({ selected: 'ikeaDate' })}
                    />
                    <label className="custom-control-label" htmlFor="customRadio1">ikea</label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      id="customRadio2"
                      name="customRadio"
                      type="radio"
                      onChange={() => this.setState({ selected: 'arrivalDate' })}

                    />
                    <label className="custom-control-label" htmlFor="customRadio2">levering </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      id="customRadio3"
                      name="customRadio"
                      type="radio"
                      onChange={() => this.setState({ selected: 'joosDepartedDate' })}

                    />
                    <label className="custom-control-label" htmlFor="customRadio3">joos accepted </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      id="customRadio4"
                      name="customRadio"
                      type="radio"
                      onChange={() => this.setState({ selected: 'joosDepartedDate' })}

                    />
                    <label className="custom-control-label" htmlFor="customRadio4">joos departed </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      className="custom-control-input"
                      id="customRadio5"
                      name="customRadio"
                      type="radio"
                      onChange={() => this.setState({ selected: 'joosDate' })}

                    />
                    <label className="custom-control-label" htmlFor="customRadio5">joos datum </label>
                  </div>
                </div>

              </Card>
              <Card className="card-frame p-3" style={{ display: 'flex', flexDirection: 'row', width: '31%' }}>
                <div >
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example-datetime-local-input"
                    >
                      Datetime
            </label>
                    <Input
                      // defaultValue="2018-11-23T10:30:00"
                      id="example-datetime-local-input"
                      type="datetime-local"
                      onChange={e => this.setState({ startDate: e.target.value })}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="example-datetime-local-input"
                    >
                      Datetime
            </label>
                    <Input
                      id="example-datetime-local-input"
                      type="datetime-local"
                      onChange={e => this.setState({ endDate: e.target.value })}

                    />
                  </FormGroup>
                </div>

              </Card>
              <Card className="card-frame p-3" style={{ display: 'flex', flexDirection: 'row', width: '31%' }}>
                <div sstyle={{ alingItems: 'flex-end' }}>
                  <Button color="primary" type="button" onClick={() => this.search()}>search</Button>
                  <p style={{color:'red'}}>{this.state.error}</p>

                </div>
              </Card>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Overzicht</h3>
                </CardHeader>
                <div className="table-responsive" ref="first-list">
                  <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" data-sort="name" scope="col">
                          Order
                        </th>
                        <th className="sort" data-sort="budget" scope="col">
                          naam
                        </th>
                        <th className="sort" data-sort="status" scope="col">
                          zip
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          city
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          adress
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          phone
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          ship date
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          delivery date
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          Joos in
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          Joos uit
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          volume
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          gewicht
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          methode
                        </th>
                        <th className="sort" data-sort="completion" scope="col">
                          driver
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list">
                      {this.createTable()}
                    </tbody>
                  </Table>
                </div>
                <CardFooter className="py-4">
                  <nav aria-label="...">

                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </>
    );
  }
}

export default shipmentPage;
