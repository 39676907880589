
import React from "react";

// reactstrap components
import { Card, CardHeader, CardBody, Container, Row, Col } from "reactstrap";
// core components
import SimpleHeader from "../../../components/Headers/SimpleHeader.jsx";

class Home extends React.Component {
  render() {
    return (
      <>
        <SimpleHeader name="Home" parentName="Components" />
        <Container className="mt--6" fluid>
          <Row className="justify-content-center">
            <Col className="card-wrapper" lg="8">
              <Card>
                <CardBody>
                  <Row className="py-3 align-items-center">
                    <Col lg="12">
                    <h1 className="mb-0">THIS PAGE IS UNDER DEVELOPMENT</h1>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Home;
