import React from "react";
// javascript plugin that creates a sortable object from a dom object
// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Modal,
  Row,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import swal from 'sweetalert';

import * as constants from "../../../constants"


class userPage extends React.Component {
  state = {
    exampleModal: false,
    addModal: false,
    selectedUser: {},
    newUser: { isAdmin: false, isDriver: false, isMagazijn: false },
    apiUsers: [],
    userRows: []
  };
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  componentDidMount() {
    fetch(constants.url+"/portal/validateToken" , {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + localStorage.getItem('token'),
      },
      })
      .then(res => res.json())
      .then(
        (result) => {
        },
        (error) => {
        this.props.history.push("/")
        }
      )
    this.fetchUsers()
  }
  selectUser(user) {
    this.setState({ selectedUser: user })
    this.toggleModal("exampleModal")
  }
  deleteUser(user){
    swal({
      title: "Bent u zeker?",
      text: "Bent u zeker dat u deze gebruiker wilt verwijderen?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        fetch(constants.url+"/management/users/"+user.userID,{
          method: 'DELETE',
          headers: {
            'Authorization': 'JWT ' + localStorage.getItem('token'),
          },
        })
          .then(res => res.json())
          .then(
            (result) => {
              this.fetchUsers()
              swal("success", "Gebruiker verwijderd", "success");
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
      } else {
        swal("Er is niets gebeurd","De gebruiker is niet verwijderd", "info");
      }
    });
   
  }
  createTable = () => {
    let table = []
    for (let i = 0; i < this.state.apiUsers.length; i++) {
      table.push(<tr>
        <th scope="row">
          <Media className="align-items-center">
            <a
              className="avatar rounded-circle mr-3"
              href="#pablo"
              onClick={e => e.preventDefault()}
            >
              <img
                alt="..."
                src={this.state.apiUsers[i].userPicture}
              />
            </a>
            <Media>
              <span className="mb-0 text-sm">
                {this.state.apiUsers[i].firstName + ' '}  {this.state.apiUsers[i].lastName}
              </span>
            </Media>
          </Media>
        </th>
        <td className="budget">{(this.state.apiUsers[i].isAdmin === 1) ? 'Ja' : 'Nee'}</td>
        <td className="budget">{(this.state.apiUsers[i].isMagazijn === 1) ? 'Ja' : 'Nee'}</td>
        <td className="budget">{(this.state.apiUsers[i].isDriver === 1) ? 'Ja' : 'Nee'}</td>
        <td className="budget">
          <Button className="btn-icon" color="primary" type="button" onClick={() => this.selectUser(this.state.apiUsers[i])}>
            <span className="btn-inner--icon mr-1">
              <i className="ni ni-ruler-pencil" />
            </span>
            <span className="btn-inner--text">pas aan</span>
          </Button>
          <Button className="btn-icon" color="primary" type="button" onClick={ e => this.deleteUser(this.state.apiUsers[i])}>
            <span className="btn-inner--icon mr-1">
              <i className="ni ni-fat-remove" />
            </span>
            <span className="btn-inner--text">delete</span>
          </Button>
        </td>
      </tr>)
    }
    // this.setState({userRows:table})
    // this.state.userRows = table
    return table
  }
  editUsers() {
    fetch(constants.url+"/management/users/edit", {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + localStorage.getItem('token'),
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        "isAdmin": (this.state.selectedUser.isAdmin) ? 1 : 0,
        "isDriver": (this.state.selectedUser.isDriver) ? 1 : 0,
        "isMagazijn": (this.state.selectedUser.isMagazijn) ? 1 : 0,
        "pincode": this.state.selectedUser.pincode,
        "wachtwoord": this.state.selectedUser.wachtwoord,
        "email": this.state.selectedUser.email,
        "firstName": this.state.selectedUser.firstName,
        "lastName": this.state.selectedUser.lastName,
        "userID": this.state.selectedUser.userID,
        "pincode": this.state.selectedUser.pincode,
        "password": this.state.selectedUser.password,
        "passwordEdit": (this.state.selectedUser.password)?true:false,
        "pincodeEdit": (this.state.selectedUser.pincode)?true:false,
        "dataEdit": true,
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.fetchUsers()
          this.toggleModal("exampleModal")
          swal("success", "Gebruiker aangepast", "success");          // this.setState({ apiUsers: result })

          // this.state.apiUsers = result
          // this.setState({
          //   isLoaded: true,
          //   items: result.items
          // });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  createUsers() {
    fetch(constants.url+"/management/users", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + localStorage.getItem('token'),
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: JSON.stringify({
        "isAdmin": (this.state.newUser.isAdmin) ? 1 : 0,
        "isDriver": (this.state.newUser.isDriver) ? 1 : 0,
        "isMagazijn": (this.state.newUser.isMagazijn) ? 1 : 0,
        "pincode": this.state.newUser.pincode,
        "wachtwoord": this.state.newUser.wachtwoord,
        "email": this.state.newUser.email,
        "firstName": this.state.newUser.firstName,
        "lastName": this.state.newUser.lastName,
        "userID": this.state.newUser.userID,
        "pincode": this.state.newUser.pincode,
        "password": this.state.newUser.password,
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.fetchUsers()
          this.toggleModal("addModal")
          swal("success", "user created", "success");
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  fetchUsers() {
    fetch(constants.url+"/management/users/all",{
      headers: {
        'Authorization': 'JWT ' + localStorage.getItem('token'),
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({ apiUsers: result })

          // this.state.apiUsers = result
          // this.setState({
          //   isLoaded: true,
          //   items: result.items
          // });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }
  render() {
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.exampleModal}
          toggle={() => this.toggleModal("exampleModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.state.selectedUser.firstName}
              {this.state.selectedUser.lastName}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
            <div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <Input placeholder="Voornaam" type="text" defaultValue={this.state.selectedUser.firstName}  onChange={e => { this.state.selectedUser.firstName = e.target.value }} />
                    {/* <Input placeholder="Voornaam" type="text" onChange={()=> this.setState({firstName:this.value})}/> */}
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    <Input placeholder="Achternaam" type="text" defaultValue={this.state.selectedUser.lastName} onChange={e => { this.state.selectedUser.lastName = e.target.value }} />
                  </div>
                </div>
                <div style={{ marginTop: '20px' }} >
                  <Input placeholder="Email" type="text" defaultValue={this.state.selectedUser.email} onChange={e => { this.state.selectedUser.email = e.target.value; }} />
                </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <div>
                    <Input placeholder="password" type="text" defaultValue={this.state.selectedUser.password} onChange={e => { this.state.selectedUser.password = e.target.value }} />
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    <Input placeholder="pincode" type="text" defaultValue={this.state.selectedUser.pincode} onChange={e => { this.state.selectedUser.pincode = e.target.value }} />
                  </div>
                </div>
              </div>
              <div>
                <p>Driver rechten: </p>
                <>
                  <label className="custom-toggle">
                    <input type="checkbox" defaultChecked={this.state.selectedUser.isDriver} onChange={() => { this.state.selectedUser.isDriver = !this.state.selectedUser.isDriver }} />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </>
              </div>
              <div>
                <p>Magazijn rechten: </p>
                <>
                  <label className="custom-toggle">
                    <input type="checkbox" defaultChecked={this.state.selectedUser.isMagazijn} onChange={() => { this.state.selectedUser.isMagazijn = !this.state.selectedUser.isMagazijn }} />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </>
              </div>
              <div>
                <p>Admin rechten: </p>
                <>
                  <label className="custom-toggle">
                    <input type="checkbox" defaultChecked={this.state.selectedUser.isAdmin} onChange={() => { this.state.selectedUser.isAdmin = !this.state.selectedUser.isAdmin }} />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </>
              </div>

            </div>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("exampleModal")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={() => this.editUsers()}>
              Save changes
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.addModal}
          toggle={() => this.toggleModal("addModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {this.state.selectedUser.firstName}
              {this.state.selectedUser.lastName}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("addModal")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div>
                <div style={{ display: 'flex' }}>
                  <div>
                    <Input placeholder="Voornaam" type="text" onChange={e => { this.state.newUser.firstName = e.target.value }} />
                    {/* <Input placeholder="Voornaam" type="text" onChange={()=> this.setState({firstName:this.value})}/> */}
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    <Input placeholder="Achternaam" type="text" onChange={e => { this.state.newUser.lastName = e.target.value }} />
                  </div>
                </div>
                <div style={{ marginTop: '20px' }} >
                  <Input placeholder="Email" type="text" onChange={e => { this.state.newUser.email = e.target.value; }} />
                </div>
                <div style={{ display: 'flex', marginTop: '20px' }}>
                  <div>
                    <Input placeholder="password" type="text" onChange={e => { this.state.newUser.password = e.target.value }} />
                  </div>
                  <div style={{ marginLeft: '20px' }}>
                    <Input placeholder="pincode" type="text" onChange={e => { this.state.newUser.pincode = e.target.value }} />
                  </div>
                </div>
              </div>
              <div>
                <p>Driver rechten: </p>
                <>
                  <label className="custom-toggle">
                    <input type="checkbox" defaultChecked={this.state.newUser.isDriver} onChange={() => { this.state.newUser.isDriver = !this.state.newUser.isDriver }} />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </>
              </div>
              <div>
                <p>Magazijn rechten: </p>
                <>
                  <label className="custom-toggle">
                    <input type="checkbox" defaultChecked={this.state.newUser.isMagazijn} onChange={() => { this.state.newUser.isMagazijn = !this.state.newUser.isMagazijn }} />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </>
              </div>
              <div>
                <p>Admin rechten: </p>
                <>
                  <label className="custom-toggle">
                    <input type="checkbox" defaultChecked={this.state.newUser.isAdmin} onChange={() => { this.state.newUser.isAdmin = !this.state.newUser.isAdmin }} />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                </>
              </div>

            </div>


          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("addModal")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={() => this.createUsers()}>
              Save changes
            </Button>
          </div>
        </Modal>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    Users
                  </h6>{" "}

                </Col>
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <Button className="btn-neutral" color="default" size="sm" onClick={() => this.toggleModal("addModal")}>
                    add user
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader className="border-0">
                  <h3 className="mb-0">Overzicht</h3>
                </CardHeader>
                <div className="table-responsive" ref="first-list">
                  <Table className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th className="sort" scope="col">
                          Gebruiker
                        </th>
                        <th className="sort" scope="col">
                          admin
                        </th>
                        <th className="sort" scope="col">
                          magazijn
                        </th>
                        <th className="sort" scope="col">
                          driver
                        </th>
                        <th scope="col" />

                      </tr>
                    </thead>
                    <tbody className="list">
                      {this.createTable()}
                    </tbody>
                  </Table>
                </div>

              </Card>
            </div>
          </Row>

        </Container>
      </>
    );
  }
}

export default userPage;
