/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Alternative from "./views/pages/dashboards/Alternative.jsx";
// import Buttons from "./views/pages/components/Buttons.jsx";
// import Calendar from "./views/pages/Calendar.jsx";
// import Cards from "./views/pages/components/Cards.jsx";
// import Charts from "./views/pages/Charts.jsx";
// import Components from "./views/pages/forms/Components.jsx";
// import Dashboard from "./views/pages/dashboards/Dashboard.jsx";
// import Elements from "./views/pages/forms/Elements.jsx";
// import Google from "./views/pages/maps/Google.jsx";
// import Grid from "./views/pages/components/Grid.jsx";
// import Icons from "./views/pages/components/Icons.jsx";
// import Lock from "./views/pages/examples/Lock.jsx";
// import Login from "./views/pages/examples/Login.jsx";
// import Notifications from "./views/pages/components/Notifications.jsx";
// import Pricing from "./views/pages/examples/Pricing.jsx";
// import Profile from "./views/pages/examples/Profile.jsx";
// import ReactBSTables from "./views/pages/tables/ReactBSTables.jsx";
// import Register from "./views/pages/examples/Register.jsx";
// import Sortable from "./views/pages/tables/Sortable.jsx";
// import Tables from "./views/pages/tables/Tables.jsx";
// import Timeline from "./views/pages/examples/Timeline.jsx";
// import Typography from "./views/pages/components/Typography.jsx";
// import Validation from "./views/pages/forms/Validation.jsx";
// import Vector from "./views/pages/maps/Vector.jsx";
// import Widgets from "./views/pages/Widgets.jsx";



import Home from "./views/pages/admin/home.jsx";
import UsersPage from "./views/pages/admin/users.jsx";
import ShipmentPage from "./views/pages/admin/shipments.jsx";
import OrderPage from "./views/pages/admin/orders.jsx";

const routes = [


  {
    path: "/shipment",
    name: "Shipments",
    icon: "ni ni-box-2 text-ortho-red",
    component: ShipmentPage,
    layout: "/admin"
  },{
    path: "/orders",
    name: "Planning",
    icon: "ni ni-calendar-grid-58 text-ortho-red",
    component: OrderPage,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-ortho-red",
    component: UsersPage,
    layout: "/admin"
  },
  // {
  //   collapse: true,
  //   name: "Communicatie",
  //   icon:"ni ni-email-83 text-ortho-red",
  //   state: "tablesCollapse",
  //   views: [
  //     {
  //       path: "/doktersatts",
  //       name: "Doktersattesten",
  //       component: Home,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/afwezigheidsatts",
  //       name: "Afwezigheidsattesten",
  //       component: Home,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "Financieel",
  //   icon: "ni ni-money-coins text-ortho-red",
  //   state: "Financieel",
  //   views: [
  //     {
  //       path: "/openstaand",
  //       name: "Openstaande betalingen",
  //       component: Home,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/betalingen",
  //       name: "Betalingen",
  //       component: Home,
  //       layout: "/admin"
  //     }
  //   ]
  // },{
  //   collapse: true,
  //   name: "Software admin",
  //   icon: "ni ni-settings text-ortho-red",
  //   state: "Software Admin",
  //   views: [
  //     {
  //       path: "/parktijk",
  //       name: "Praktijkbeheer",
  //       component: Home,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/personeel",
  //       name: "Personeelbeheer",
  //       component: Home,
  //       layout: "/admin"
  //     }
  //   ]
  // },
  // {
  //   collapse: true,
  //   name: "OG components",
  //   icon: "ni ni-planet text-blue",
  //   state: "componentsCollapse",
  //   views: [
  //     {
  //       collapse: true,
  //       name: "Componentss",
  //       state: "multiCollapse",
  //       views:[{
  //         path: "/buttons",
  //         name: "Buttons",
  //         component: Buttons,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/cards",
  //         name: "Cards",
  //         name: "Cards",
  //         component: Cards,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/grid",
  //         name: "Grid",
  //         component: Grid,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/notifications",
  //         name: "Notifications",
  //         component: Notifications,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/icons",
  //         name: "Icons",
  //         component: Icons,
  //         layout: "/admin"
  //       },
  //       {
  //         path: "/typography",
  //         name: "Typography",
  //         component: Typography,
  //         layout: "/admin"
  //       },
  //       {
  //         collapse: true,
  //         name: "Multi Level",
  //         state: "multiCollapse",
  //         views: [
  //           {
  //             path: "#pablo",
  //             name: "Third level menu",
  //             component: () => {},
  //             layout: "/"
  //           },
  //           {
  //             path: "#pablo",
  //             name: "Just another link",
  //             component: () => {},
  //             layout: "/"
  //           },
  //           {
  //             path: "#pablo",
  //             name: "One last link",
  //             component: () => {},
  //             layout: "/"
  //           }
  //         ]
  //       }
  //     ]
  //     },
  //     {
  //       collapse: true,
  //       name: "Dashboards",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/dashboard",
  //           name: "Dashboard",
  //           component: Dashboard,
  //           layout: "/admin"
  //         },
  //         {
  //           path: "/alternative-dashboard",
  //           name: "Alternative",
  //           component: Alternative,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       collapse: true,
  //       name: "Examples",
  //       state: "multiCollapse",
  //       views: [
  //         {
  //           path: "/pricing",
  //           name: "Pricing",
  //           component: Pricing,
  //           layout: "/auth"
  //         },
  //         {
  //           path: "/login",
  //           name: "Login",
  //           component: Login,
  //           layout: "/auth"
  //         },
  //         {
  //           path: "/register",
  //           name: "Register",
  //           component: Register,
  //           layout: "/auth"
  //         },
  //         {
  //           path: "/lock",
  //           name: "Lock",
  //           component: Lock,
  //           layout: "/auth"
  //         },
  //         {
  //           path: "/timeline",
  //           name: "Timeline",
  //           component: Timeline,
  //           layout: "/admin"
  //         },
  //         {
  //           path: "/profile",
  //           name: "Profile",
  //           component: Profile,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       collapse: true,
  //       name: "Forms",
  //       state: "formsCollapse",
  //       views: [
  //         {
  //           path: "/elements",
  //           name: "Elements",
  //           component: Elements,
  //           layout: "/admin"
  //         },
  //         {
  //           path: "/components",
  //           name: "Components",
  //           component: Components,
  //           layout: "/admin"
  //         },
  //         {
  //           path: "/validation",
  //           name: "Validation",
  //           component: Validation,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       collapse: true,
  //       name: "Tables",
  //       state: "tablesCollapse",
  //       views: [
  //         {
  //           path: "/tables",
  //           name: "Tables",
  //           component: Tables,
  //           layout: "/admin"
  //         },
  //         {
  //           path: "/sortable",
  //           name: "Sortable",
  //           component: Sortable,
  //           layout: "/admin"
  //         },
  //         {
  //           path: "/react-bs-table",
  //           name: "React BS Tables",
  //           component: ReactBSTables,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       collapse: true,
  //       name: "Maps",
  //       state: "mapsCollapse",
  //       views: [
  //         {
  //           path: "/google",
  //           name: "Google",
  //           component: Google,
  //           layout: "/admin"
  //         },
  //         {
  //           path: "/vector",
  //           name: "Vector",
  //           component: Vector,
  //           layout: "/admin"
  //         }
  //       ]
  //     },
  //     {
  //       path: "/widgets",
  //       name: "Widgets",
  //       component: Widgets,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/charts",
  //       name: "Charts",
  //       component: Charts,
  //       layout: "/admin"
  //     },
  //     {
  //       path: "/calendar",
  //       name: "Calendar",
  //       component: Calendar,
  //       layout: "/admin"
  //     }
  //   ]
  // }
  

  
];

export default routes;
